<script>
import appConfig from "@/app.config";

/**
 * Maintenance component
 */
export default {
  page: {
    title: "Maintenance",
    meta: [{ name: "description", content: appConfig.description }],
  },
};
</script>

<template>
  <div class="my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center mb-5">
            <router-link to="/" class="auth-logo">
              <img
                src="@/assets/images/logo-dark.png"
                alt=""
                height="24"
                class="auth-logo-dark"
              />
              <img
                src="@/assets/images/logo-light.png"
                alt=""
                height="24"
                class="auth-logo-light"
              />
            </router-link>

            <h3 class="mt-5">Site is Under Maintenance</h3>
            <p class="font-size-15 text-muted">
              Please check back in sometime.
            </p>
          </div>
          <div class="card overflow-hidden">
            <div class="row g-0">
              <div class="col-lg-6  order-2 order-lg-1">
                <div class="p-lg-5 p-4">
                  <div class="maintenance-box">
                    <div class="d-flex">
                      <div class="avatar-xs me-3">
                        <span
                          class="avatar-title bg-primary bg-soft rounded-circle"
                        >
                          <i
                            class="mdi mdi-access-point-network text-primary"
                          ></i>
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-15 text-uppercase">
                          Why is the Site Down?
                        </h5>
                        <p class="text-muted">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration.
                        </p>
                      </div>
                    </div>

                    <div class="d-flex mt-4">
                      <div class="avatar-xs me-3">
                        <span
                          class="avatar-title bg-primary bg-soft rounded-circle"
                        >
                          <i class="mdi mdi-clock-outline text-primary"></i>
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-15 text-uppercase">
                          What is the Downtime?
                        </h5>
                        <p class="text-muted">
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical.
                        </p>
                      </div>
                    </div>

                    <div class="d-flex mt-4">
                      <div class="avatar-xs me-3">
                        <span
                          class="avatar-title bg-primary bg-soft rounded-circle"
                        >
                          <i class="mdi mdi-email-outline text-primary"></i>
                        </span>
                      </div>

                      <div class="flex-1">
                        <h5 class="font-size-15 text-uppercase">
                          Do you need Support?
                        </h5>
                        <p class="text-muted mb-0">
                          If you are going to use a passage of Lorem Ipsum, you
                          need to be sure there isn't anything embar..
                          <a
                            href="mailto:no-reply@domain.com"
                            class="text-decoration-underline"
                            >no-reply@domain.com</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 order-1 order-lg-2">
                <div class="p-lg-5 p-4 bg h-100">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/maintenance.png"
                      alt=""
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
